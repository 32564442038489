// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item{
    width: 350px;
}
.item p{
    margin: 6px 0px;
}
.item-prices{
    display: flex;
    gap: 20px;
}
.item-price-new{
    color: #374151;
    font-size: 18px;
    font-weight: 600;

}
.item-price-old{
    color:#8c8c8c;
    font-size: 18px;
    font-weight: 500;
    text-decoration: line-through;
}
.item:hover{
    transform: scale(1.05);
    transition: 0.6s;
}`, "",{"version":3,"sources":["webpack://./src/Components/Item/Item.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,eAAe;AACnB;AACA;IACI,aAAa;IACb,SAAS;AACb;AACA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;;AAEpB;AACA;IACI,aAAa;IACb,eAAe;IACf,gBAAgB;IAChB,6BAA6B;AACjC;AACA;IACI,sBAAsB;IACtB,gBAAgB;AACpB","sourcesContent":[".item{\n    width: 350px;\n}\n.item p{\n    margin: 6px 0px;\n}\n.item-prices{\n    display: flex;\n    gap: 20px;\n}\n.item-price-new{\n    color: #374151;\n    font-size: 18px;\n    font-weight: 600;\n\n}\n.item-price-old{\n    color:#8c8c8c;\n    font-size: 18px;\n    font-weight: 500;\n    text-decoration: line-through;\n}\n.item:hover{\n    transform: scale(1.05);\n    transition: 0.6s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
