// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shopcategory-banner{
    display:block;
    margin: 30px auto;
    width: 82%;
}
.shopcategory-indexSort{
    display: flex;
    margin: 0px 170px;
    justify-content: space-between;
    align-items: center;
}
.shopcategory-sort{
    padding: 10px 20px;
    border-radius: 40px;
    border: 1px solid #888;

}
.shopcategory-indexSort p span{
    font-weight: 600;

}
.shopcategory-products{
    margin: 20px 170px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 80px;
    
}`, "",{"version":3,"sources":["webpack://./src/Pages/CSS/ShopCategory.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,UAAU;AACd;AACA;IACI,aAAa;IACb,iBAAiB;IACjB,8BAA8B;IAC9B,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,sBAAsB;;AAE1B;AACA;IACI,gBAAgB;;AAEpB;AACA;IACI,kBAAkB;IAClB,aAAa;IACb,sCAAsC;IACtC,aAAa;;AAEjB","sourcesContent":[".shopcategory-banner{\n    display:block;\n    margin: 30px auto;\n    width: 82%;\n}\n.shopcategory-indexSort{\n    display: flex;\n    margin: 0px 170px;\n    justify-content: space-between;\n    align-items: center;\n}\n.shopcategory-sort{\n    padding: 10px 20px;\n    border-radius: 40px;\n    border: 1px solid #888;\n\n}\n.shopcategory-indexSort p span{\n    font-weight: 600;\n\n}\n.shopcategory-products{\n    margin: 20px 170px;\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr 1fr;\n    row-gap: 80px;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
