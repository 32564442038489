import React from 'react'
import './Navbar.css'
import logo from '../Assets/logo.png'
import cart_icon from '../Assets/cart_icon.png'
import { useState } from 'react'
import { Link } from 'react-router-dom'


const Navbar = () => {
    const [menu,setMenu] = useState("Shop");
  return (
    <div className='navbar'>
    
      <div className="nav-logo">
       <img src={logo} alt="" />
       <p>SHOPPER</p>
       
      </div>
      <ul className="nav-menu">
      <li onClick={()=>{setMenu("shop")}}><Link style={{textDecoration : 'none'}} to='/'>Shop</Link>{menu==="shop"?<hr/>:<></>}</li>
      <li onClick={()=>{setMenu("men")}}><Link style={{textDecoration : 'none'}} to='/mens'>Men</Link>{menu==="men"?<hr/>:<></>}</li>
      <li onClick={()=>{setMenu("women")}}><Link style={{textDecoration : 'none'}} to='/womens'>Women</Link>{menu==="women"?<hr/>:<></>}</li>
      <li onClick={()=>{setMenu("kids")}}><Link style={{textDecoration : 'none'}} to='/kids'>Kids</Link>{menu==="kids"?<hr/>:<></>}</li>
      
      </ul>

      <div className="nav-login-cart">
      <Link to='/login'><button>Login</button></Link>
      <Link to='/cart'><img src={cart_icon} alt="" /></Link>
      <div className="nav-cart-counter">0</div></div>
      






    </div>
  )
}

export default Navbar
