// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newsletter{
    width: 65%;
    height:40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 0px 140px;
    margin-bottom: 150px;
    background: linear-gradient(180deg, #fde1ff 0%, #e1ffea22 60%);
    gap: 30px;
}
.newsletter h1{
    color: #454545;
    font-size: 55px;
    font-weight: 600;
}
.newsletter p{
    color: #454545;
    font-size: 20px;

}
.newsletter div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    width: 730px;
    height: 70px;
    border-radius: 80px;
    border: 1px solid #e3e3e3;
}
.newsletter input{
    width: 500px;
    margin-left: 30px;
    border: none;
    outline: none;
    color: #616161;
    font-family: Poppins;
    font-size: 16px;
}
.newsletter div button{
    width: 210px;
    height: 70px;
    border-radius: 80px;
    background: black;
    color: white;
    font-size: 16px;
    cursor: pointer;

}`, "",{"version":3,"sources":["webpack://./src/Components/NewsLetter/NewsLetter.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;IAClB,oBAAoB;IACpB,8DAA8D;IAC9D,SAAS;AACb;AACA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,eAAe;;AAEnB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,iBAAiB;IACjB,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;AAC7B;AACA;IACI,YAAY;IACZ,iBAAiB;IACjB,YAAY;IACZ,aAAa;IACb,cAAc;IACd,oBAAoB;IACpB,eAAe;AACnB;AACA;IACI,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,iBAAiB;IACjB,YAAY;IACZ,eAAe;IACf,eAAe;;AAEnB","sourcesContent":[".newsletter{\n    width: 65%;\n    height:40vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    margin: auto;\n    padding: 0px 140px;\n    margin-bottom: 150px;\n    background: linear-gradient(180deg, #fde1ff 0%, #e1ffea22 60%);\n    gap: 30px;\n}\n.newsletter h1{\n    color: #454545;\n    font-size: 55px;\n    font-weight: 600;\n}\n.newsletter p{\n    color: #454545;\n    font-size: 20px;\n\n}\n.newsletter div{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    background: white;\n    width: 730px;\n    height: 70px;\n    border-radius: 80px;\n    border: 1px solid #e3e3e3;\n}\n.newsletter input{\n    width: 500px;\n    margin-left: 30px;\n    border: none;\n    outline: none;\n    color: #616161;\n    font-family: Poppins;\n    font-size: 16px;\n}\n.newsletter div button{\n    width: 210px;\n    height: 70px;\n    border-radius: 80px;\n    background: black;\n    color: white;\n    font-size: 16px;\n    cursor: pointer;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
