// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-collections{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 100px;
}
.new-collections h1{
    color: #171717;
    font-size: 50px;
    font-weight: 600;
}
.new-collections hr{
    width: 200px;
    
    height: 6px;
    border-radius: 10px;
    background: #252525;
}
.collections{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 50px;
    gap: 30px;
}`, "",{"version":3,"sources":["webpack://./src/Components/NewCollections/NewCollections.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;IACT,oBAAoB;AACxB;AACA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,YAAY;;IAEZ,WAAW;IACX,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,sCAAsC;IACtC,gBAAgB;IAChB,SAAS;AACb","sourcesContent":[".new-collections{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 10px;\n    margin-bottom: 100px;\n}\n.new-collections h1{\n    color: #171717;\n    font-size: 50px;\n    font-weight: 600;\n}\n.new-collections hr{\n    width: 200px;\n    \n    height: 6px;\n    border-radius: 10px;\n    background: #252525;\n}\n.collections{\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr 1fr;\n    margin-top: 50px;\n    gap: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
