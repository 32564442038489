// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popular{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    height: 90vh;
}
.popular h1{
    color: #171717;
    font-size: 50px;
    font-weight: 600;
}
.popular hr{
    width: 200px;
    
    height: 6px;
    border-radius: 10px;
    background: #252525;
}
.popular-item{
    margin-top: 50px;
    display: flex;
    
    gap:30px;
}
.item-prices{
    display: flex;
    gap: 30px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Popular/Popular.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;IACT,YAAY;AAChB;AACA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,YAAY;;IAEZ,WAAW;IACX,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,aAAa;;IAEb,QAAQ;AACZ;AACA;IACI,aAAa;IACb,SAAS;AACb","sourcesContent":[".popular{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 10px;\n    height: 90vh;\n}\n.popular h1{\n    color: #171717;\n    font-size: 50px;\n    font-weight: 600;\n}\n.popular hr{\n    width: 200px;\n    \n    height: 6px;\n    border-radius: 10px;\n    background: #252525;\n}\n.popular-item{\n    margin-top: 50px;\n    display: flex;\n    \n    gap:30px;\n}\n.item-prices{\n    display: flex;\n    gap: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
