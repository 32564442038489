// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px
}
.footer-logo{
    display: flex;
    align-items: center;
    gap: 20px;
}
.footer-logo p{
    color: #383838;
    font-size: 46px;
    font-weight: 700;
}
.footer-links{
    display: flex;
    list-style: none;
    gap: 50px;
    color: #252525;
    font-size: 20px;
}
.footer-links li{
    cursor: pointer;
}
.footer-social-icon{
    display: flex;
    gap: 20px;

}
.footer-icons-container{
    padding: 10px;
    padding-bottom: 6px;
    background: #fbfbfb;
    border: 1px solid #ebebeb;
}
.footer-copyright{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 100%;
    margin-bottom: 30px;
    color: #1a1a1a;
    font-size: 20px;
}
.footer-copyright hr{
    width: 80%;
    border: none;
    border-radius: 10px;
    height: 3px;
    background: #ebebeb;
}`, "",{"version":3,"sources":["webpack://./src/Components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB;AACJ;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;AACA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,gBAAgB;IAChB,SAAS;IACT,cAAc;IACd,eAAe;AACnB;AACA;IACI,eAAe;AACnB;AACA;IACI,aAAa;IACb,SAAS;;AAEb;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,yBAAyB;AAC7B;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;IACT,WAAW;IACX,mBAAmB;IACnB,cAAc;IACd,eAAe;AACnB;AACA;IACI,UAAU;IACV,YAAY;IACZ,mBAAmB;IACnB,WAAW;IACX,mBAAmB;AACvB","sourcesContent":[".footer{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: 50px\n}\n.footer-logo{\n    display: flex;\n    align-items: center;\n    gap: 20px;\n}\n.footer-logo p{\n    color: #383838;\n    font-size: 46px;\n    font-weight: 700;\n}\n.footer-links{\n    display: flex;\n    list-style: none;\n    gap: 50px;\n    color: #252525;\n    font-size: 20px;\n}\n.footer-links li{\n    cursor: pointer;\n}\n.footer-social-icon{\n    display: flex;\n    gap: 20px;\n\n}\n.footer-icons-container{\n    padding: 10px;\n    padding-bottom: 6px;\n    background: #fbfbfb;\n    border: 1px solid #ebebeb;\n}\n.footer-copyright{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 30px;\n    width: 100%;\n    margin-bottom: 30px;\n    color: #1a1a1a;\n    font-size: 20px;\n}\n.footer-copyright hr{\n    width: 80%;\n    border: none;\n    border-radius: 10px;\n    height: 3px;\n    background: #ebebeb;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
