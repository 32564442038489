// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.offers{
    width:65%;
    height:60vh;
    display: flex;
    margin: auto;
    padding: 0px 140px;
    margin-bottom: 150px;
    background: linear-gradient(180deg, #fde1ff,#e1ffea22 60%);
    margin-top: 130px;
}
.offers-left{
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
}
.offers-left h1{
    color:#171717;
    font-size: 80px;
    font-weight: 600;
}
.offers-left p{
    color: #171717;
    font-size: 22px;
    font-weight: 600;
}
.offers-left button{
    width: 282px;
    height: 70px;
    border-radius: 35px;
    background: #ff4141;
    border: none;
    color: white;
    font-size: 22px;
    font-weight: 500;
    margin-top: 30px;
    cursor:pointer;
}
.offers-right{
    flex:  1 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 110px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Offers/Offers.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,WAAW;IACX,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,oBAAoB;IACpB,0DAA0D;IAC1D,iBAAiB;AACrB;AACA;IACI,SAAO;IACP,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,UAAQ;IACR,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;AACtB","sourcesContent":[".offers{\n    width:65%;\n    height:60vh;\n    display: flex;\n    margin: auto;\n    padding: 0px 140px;\n    margin-bottom: 150px;\n    background: linear-gradient(180deg, #fde1ff,#e1ffea22 60%);\n    margin-top: 130px;\n}\n.offers-left{\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    margin-top: 10px;\n}\n.offers-left h1{\n    color:#171717;\n    font-size: 80px;\n    font-weight: 600;\n}\n.offers-left p{\n    color: #171717;\n    font-size: 22px;\n    font-weight: 600;\n}\n.offers-left button{\n    width: 282px;\n    height: 70px;\n    border-radius: 35px;\n    background: #ff4141;\n    border: none;\n    color: white;\n    font-size: 22px;\n    font-weight: 500;\n    margin-top: 30px;\n    cursor:pointer;\n}\n.offers-right{\n    flex:  1;\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    padding-top: 110px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
