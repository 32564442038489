// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero{
    height: 120vh;
    background:linear-gradient(180deg,#fde1ff,#e1ffea22 60%);
    display:flex;
    
    
}
.hero-left{
    flex:1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    gap: 20px;
    padding-left: 180px;
    line-height: 1.1;
}
.hero-left h2{
    color:#090909;
    font-size: 26px;
    font-weight: 600;
}
.hero-left p{
    color: #171717;
    font-size: 100px;
    font-weight: 700;
}
.hero-hand-icon{
    display: flex;
    align-items: center;
    gap: 20px;
}
.hero-hand-icon img{
    width: 105px;
}
.hero-latest-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 310px;
    height: 70px;
    border-radius: 75px;
    margin-top: 30px;
    background:#ff4141;
    color: white;
    font-size: 22px;
    font-weight:500;

}
.hero-right{
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content:center;
}`, "",{"version":3,"sources":["webpack://./src/Components/Hero/Hero.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,wDAAwD;IACxD,YAAY;;;AAGhB;AACA;IACI,QAAM;IACN,aAAa;IACb,sBAAsB;IACtB,uBAAuB;;IAEvB,SAAS;IACT,mBAAmB;IACnB,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;AACA;IACI,YAAY;AAChB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,eAAe;;AAEnB;AACA;IACI,SAAO;IACP,aAAa;IACb,mBAAmB;IACnB,sBAAsB;AAC1B","sourcesContent":[".hero{\n    height: 120vh;\n    background:linear-gradient(180deg,#fde1ff,#e1ffea22 60%);\n    display:flex;\n    \n    \n}\n.hero-left{\n    flex:1;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    \n    gap: 20px;\n    padding-left: 180px;\n    line-height: 1.1;\n}\n.hero-left h2{\n    color:#090909;\n    font-size: 26px;\n    font-weight: 600;\n}\n.hero-left p{\n    color: #171717;\n    font-size: 100px;\n    font-weight: 700;\n}\n.hero-hand-icon{\n    display: flex;\n    align-items: center;\n    gap: 20px;\n}\n.hero-hand-icon img{\n    width: 105px;\n}\n.hero-latest-btn{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 15px;\n    width: 310px;\n    height: 70px;\n    border-radius: 75px;\n    margin-top: 30px;\n    background:#ff4141;\n    color: white;\n    font-size: 22px;\n    font-weight:500;\n\n}\n.hero-right{\n    flex: 1;\n    display: flex;\n    align-items: center;\n    justify-content:center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
